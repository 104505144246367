<template>
    <div id="app">

        <el-container style="height: 100%;">

            <el-aside style="width: 200px">
                <el-menu :default-active="$route.path" :default-openeds="['craw']" background-color="#1e345a" text-color="#fff" active-text-color="#fff" :router="true">

                    <el-menu-item v-for="(item, i) in $router.options.routes.slice(1)" :key="item.path" :index="item.path">
                        <span slot="title">
                            <i :class="`el-icon-${['chat-dot-square', 'document-copy', 'picture'][i]}`"></i>
                            <span>{{ item.name }}</span>
                        </span>
                    </el-menu-item>

                </el-menu>
                <img class="logo" src="./assets/logo.png" alt="" @click="openIndex">
            </el-aside>

            <el-container>

                <el-header>
                    <el-button type="primary" plain size="mini" @click="logout" :loading="logoutLoading">退出登录</el-button>
                </el-header>

                <el-main>
                    <transition name="fade">
                        <router-view></router-view>
                    </transition>
                </el-main>

            </el-container>
        </el-container>

    </div>
</template>

<script>

export default {
    name: 'App',
    data() {
        return {
            logoutLoading: false
        }
    },
    methods: {
        openIndex() {
            window.open('//peal.cc')
        },
        logout() {
            let that = this
            that.$confirm('确定退出登陆吗？')
                .then(() => {
                    that.ajax({
                        url: '/admin/logout',
                        type: 'GET',
                        beforeSend() {
                            that.logoutLoading = true
                        },
                        complete() {
                            that.logoutLoading = false
                        },
                        success() {
                            that.$message({
                                message: '退出登录成功',
                                type: 'success'
                            });
                            that.$router.push('/login')
                        },
                        error(res) {
                            that.$message.error(res.message);
                        },
                    })
                })
                .catch(() => { })
        }
    }
}
</script>

<style>
html,
body {
    height: 100%;
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
</style>
<style lang="less" scoped>
#app {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #000c45;
    height: 100%;
}

.el-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    border-bottom: 1px solid #ebeef5;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.el-aside {
    padding-top: 80px;
    text-align: center;
    background-color: #1e345a;

    .logo {
        position: fixed;
        bottom: 0;
        left: 50px;
        width: 100px;
        cursor: pointer;
        z-index: 2;
        transform: scale(1);
        transform-origin: bottom;
        transition: transform .3s;

        &:hover {
            transform: scale(1.1);
        }
    }

    .el-menu {
        border-right: none;
    }

    .el-menu-item,
    .el-submenu__title {
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
    }

    .el-submenu__title i {
        color: #dedede;
    }

    .el-menu-item.is-active {
        background-color: #2f4b77 !important;
        border-left-color: #06886e;
    }

    /deep/ .el-menu-item {
        padding-left: 10px !important;
    }
}

.el-main {
    background-color: #fbf9fa;
}
</style>
