import axios from 'axios'
import { baseUrl } from './env'

const ajax = function (params) {
    if (!params.url) return false

    let requestParams = {
        url: params.url,
        method: params.type || 'get',
        baseURL: baseUrl,
        headers: params.headers || '',
        timeout: params.timeout || 0,
        responseType: params.dataType || 'json',
        withCredentials: true
    }

    if (requestParams.method === 'post' || requestParams.method === 'POST') requestParams.data = params.data; else requestParams.params = params.data;

    params.beforeSend && params.beforeSend()
    axios(requestParams).then(res => {
        if (res.data.status === 100000) {
            params.success && params.success(res.data)
        }
        else {
            params.error && params.error(res.data)
        }
        params.complete && params.complete()
    }).catch(err => {
        // 登录失效跳转登录
        if (err && err.response && err.response.status === 401) {
            location.href = location.origin + '/#/login'
        }
        params.complete && params.complete()
        params.error && params.error((err.response && err.response.data) || { message: '服务器错误，请稍候重试' })
    })
}

export default ajax