import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import ajax from './assets/ajax'
import base from './assets/base'
import env from './assets/env'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueRouter)

Vue.prototype.ajax = ajax
Vue.prototype.base = base
Vue.prototype.env = env

// 配置页面路由
// import Home from './page/home'
const router = new VueRouter({
  routes: [
    // { path: '/', name: '首页', component: Home },
    { path: '/login', name: '登录', component: () => import('./page/login') },
    { path: '/', name: '留言管理', component: () => import('./page/comment') },
    { path: '/blog', name: '博客管理', component: () => import('./page/blog') },
    { path: '/photo', name: '相册管理', component: () => import('./page/photo') },
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
